body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


@font-face {
  font-family: 'Oswald';
  font-weight: 300;
  font-style: normal;
  src: url('/fonts/Oswald-300/Oswald-300.eot');
  src: url('/fonts/Oswald-300/Oswald-300.eot?#iefix') format('embedded-opentype'),
       local('Oswald Light'),
       local('Oswald-300'),
       url('/fonts/Oswald-300/Oswald-300.woff2') format('woff2'),
       url('/fonts/Oswald-300/Oswald-300.woff') format('woff'),
       url('/fonts/Oswald-300/Oswald-300.ttf') format('truetype'),
       url('/fonts/Oswald-300/Oswald-300.svg#Oswald') format('svg');
}

@font-face {
  font-family: 'Oswald';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/Oswald-regular/Oswald-regular.eot');
  src: url('/fonts/Oswald-regular/Oswald-regular.eot?#iefix') format('embedded-opentype'),
       local('Oswald Regular'),
       local('Oswald-regular'),
       url('/fonts/Oswald-regular/Oswald-regular.woff2') format('woff2'),
       url('/fonts/Oswald-regular/Oswald-regular.woff') format('woff'),
       url('/fonts/Oswald-regular/Oswald-regular.ttf') format('truetype'),
       url('/fonts/Oswald-regular/Oswald-regular.svg#Oswald') format('svg');
}

@font-face {
  font-family: 'Oswald';
  font-weight: 700;
  font-style: normal;
  src: url('/fonts/Oswald-700/Oswald-700.eot');
  src: url('/fonts/Oswald-700/Oswald-700.eot?#iefix') format('embedded-opentype'),
       local('Oswald Bold'),
       local('Oswald-700'),
       url('/fonts/Oswald-700/Oswald-700.woff2') format('woff2'),
       url('/fonts/Oswald-700/Oswald-700.woff') format('woff'),
       url('/fonts/Oswald-700/Oswald-700.ttf') format('truetype'),
       url('/fonts/Oswald-700/Oswald-700.svg#Oswald') format('svg');
}